import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";

function Paging(props) {

    const pageItem = props?.pagination;
    const [currentPage, setCurrentPage] = useState(pageItem.pageNumber);

    const pageClick = (e) => {
        setCurrentPage(parseInt(e));
        props.onPageChange(parseInt(e));

    }

    const minShowing = () => {
        return pageItem.totalCount === 0 ? 0 : (currentPage - 1) * pageItem.countPerPage + 1;

    }

    const maxShowing = () => {
        const maybeMax = currentPage * pageItem.countPerPage;
        return Math.min(maybeMax, pageItem.totalCount)

    }

    let page = pageItem.pageNumber
    let pages = pageItem.totalPages
    let items = [];
    for (let number = page; number <= (page + 4) && number < pages; number++) {
        items.push(
            <Pagination.Item activeLabel="" data-testid={`page-${number}`} onClick={() => pageClick(number)} key={number} active={number === page}>
                {number}
            </Pagination.Item>
        );
    }

    return (
        <>
            <Pagination className="pagination pagination-md justify-content-end">
                <Pagination.First data-testid="firstPage" onClick={() => pageClick(1)} disabled={page === 1 ? true : false} />
                <Pagination.Prev onClick={() => pageClick(page - 1)} disabled={page === 1 ? true : false} />
                {items}
                <Pagination.Ellipsis />
                <Pagination.Item onClick={() => pageClick(pages)} active={currentPage === pages}>{pages}</Pagination.Item>
                <Pagination.Next data-testid="nextPage" onClick={() => pageClick(page + 1)} disabled={page === pages ? true : false} />
                <Pagination.Last data-testid="lastPage" onClick={() => pageClick(pages)} disabled={page === pages ? true : false} />
            </Pagination>
            <div className="dataTables_info justify-content-end" role="status" aria-live="polite">Showing {minShowing()} to {maxShowing()} of {pageItem?.totalCount} entries</div>
        </>

    );
}

export default Paging;

