import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Paging from "../Shared/Common-UI/Pagination";
import LoadSpinner from "../Shared/Common-UI/LoadSpinner";
import apiClient from "../../api";

function Formulary() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formulary, setFormulary] = useState({
    search: "",
    status: "active",
  });
  const [formularyList, setFormularyList] = useState([]);
  const [pagination, setPagination] = useState({
    countPerPage: 10,
    pageNumber: 1,
    totalCount: 0,
    totalPages: 0,
  });
  const [remountComponent, setRemountComponent] = useState(0);

  const searchFormulary = useCallback(async (pageNumber) => {
    const isActive = formulary.status === "active";
    let pageNum = pageNumber ? pageNumber : 1;
    setLoading(true);
    try {
      let response = await apiClient()?.get(`/api/formularies?page=${pageNum}&size=${pagination.countPerPage}&search=${formulary.search}&isActive=${isActive}`);
      if (response) {
        
        setFormularyList(response?.data?.content);
        pagination.countPerPage = response?.data?.page?.size;
        pagination.pageNumber = response?.data?.page?.number;
        pagination.totalCount = response?.data?.page?.totalElements;
        pagination.totalPages = response?.data?.page?.totalPages
        setPagination(pagination);
      }
      setLoading(false);
      setRemountComponent(Math.random());

    } catch (error) {
      setLoading(false);
    }
  }, [pagination, formulary.search, formulary.status]);

  useEffect(() => {
    searchFormulary();
  }, [searchFormulary]);

  const clearSearch = () => {
    document.getElementById("search").value = "";
    formulary.search = "";
    setFormulary(formulary);
    searchFormulary();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "search") {
      formulary.search = value;
    } else if (name === "status") {
      formulary.status = value;
    }
    setFormulary(formulary);
  };

  const handlePageClick = (page) => {
    pagination.pageNumber = page;
    setPagination(pagination);
    searchFormulary(pagination.pageNumber);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    searchFormulary(1);
    pagination.pageNumber = 1;
    setPagination(pagination);

  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 py-3">
          <div>
            <h1>In-House Formulary Log</h1>
          </div>
          {loading ? <LoadSpinner /> : ""}
          <>
            <div className={loading ? 'd-none' : ''}>
              <form onSubmit={handleSubmit}>
                <div className="form-group row mt-5">
                  <label htmlFor="search" className="col-sm-2 col-form-label">
                    Description/ID
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      name="search"
                      data-testid="search"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="status" className="col-sm-2 col-form-label">
                    Status
                  </label>
                  <div className="col-sm-2">
                    <select
                      className="form-control"
                      id="name"
                      name="status"
                      data-testid="status"
                      onChange={handleInputChange}
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <div className=" p-2 text-right">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-testid="searchBtn"
                        onClick={handleSubmit}>
                        Search
                      </button>
                      <button type="button" className="btn btn-cancel" data-testid="clearBtn" onClick={clearSearch}>
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row mt-4">
                <div className="col-6">
                  <div className="p-2">
                    <button type="button" className="btn btn-primary" data-testid="addBtn" onClick={() => navigate("/formularies/add")}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-1">
                <div className=" col-8">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formularyList?.length === 0 ? (
                          <tr>
                            <td className="no-data" colSpan="4">
                              <aside>No Data Found</aside>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {formularyList?.map((val, key) => {
                              return (
                                <tr key={`Edit-Formulary-${key}`}>
                                  <td data-testid={val.id}>{val.id}</td>
                                  <td data-testid={val.description}>{val.description}</td>
                                  <td>{val.isActive ? 'Active' : 'InActive'}</td>
                                  <td><button  className="anchor-button" data-testid={'edit-' + val.id} onClick={() => navigate(`/formularies/edit/${val.id}`)}>Edit</button></td>
                                </tr>
                              );
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                    <div key={remountComponent}>
                      {pagination && pagination.totalCount > 10 && (
                        <Paging
                          pagination={pagination}
                          onPageChange={handlePageClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default Formulary;
