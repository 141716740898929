import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//helpers
import Moment from 'react-moment';
import LoadSpinner from "../Shared/Common-UI/LoadSpinner";
import SimpleModal from "../Shared/SimpleModal";
import YesNoModal from "../Shared/YesNoModal";
import JsonToCSVDownload from "../Utility/Utility";
import apiClient from "../../api";
// Rest of the code...


function FormularyAdd() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const modalProp = { content: [], isError: false, size: 'sm' };
  const [showModal, setShowModal] = useState(false);
  const [modalMsg, setModalMsg] = useState(modalProp);
  const [prevStatus, setPrevStatus] = useState(true);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [activeInactiveWarningMsg, setActiveInactiveWarningMsg] = useState('');
  const status = [
    { key: "3", value: "Excluded" },
    { key: "70", value: "In Stock" },
    { key: "-1", value: "No Formulary" },
    { key: "10", value: "Not On Formulary" },
    { key: "5", value: "Not Reimbursable" },
    { key: "30", value: "On Formulary" },
    { key: "60", value: "Preferred" },
    {
      key: "22",
      value: "Prior Authorization May Be Required",
    },
    {
      key: "20",
      value: "Prior Authorization Required",
    },
    { key: "11", value: "Restricted" },
  ];
  const [formularyStatus] = useState(status);

  const [formulary, setFormulary] = useState({
    id: 0,
    description: "",
    defaultGenericFormularyStatus: "",
    defaultBrandFormularyStatus: "",
    defaultOTCFormularyStatus: "",
    file: "",
    isActive: true,
  });
  const [formError, setFormError] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setLoading(true);
        await apiClient().get(`/api/formularies/${id}`).then(
          (response) => {
            setFormulary(response.data);
            setPrevStatus(response.data.isActive);
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        );
      }
    }
    fetchData();
  }, [id]);

  //regExp method
  const patten = /\s/g;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormulary({ ...formulary, [name]: value });
    setFormError({
      ...formError,
      [name]: value ? "" : "This field is required",
    });
  };

  const onKeyUp = (event) => {
    const { name, value } = event.target;
    var isSpace = patten.test(event.target.value[0])
    if (isSpace) {
      setFormError({
        ...formError,
        [name]: value === isSpace ? "" : "Description cannot begin with a space",
      });
    }
  }

  const changeHandler = (e) => {
    setFormulary((prevState) => ({
      ...prevState,
      file: e.target.files[0],
    }));
    setFormError((prevState) => ({
      ...prevState,
      file: "",
    }));
  };

  const formularyAdd = async () => {
    let formError = formularyValidation(formulary);
    if (!formError.isFormValid) {
      setFormError(formError);
      return false;
    }

    delete formulary.cancelDate;
    var formData = new FormData();
    for (var key in formulary) {
      formData.append(key, formulary[key]);
    }

    const config = { headers: { "content-type": "multipart/form-data" } };
    const method = id ? 'put' : 'post';
    const url = '/api/formularies';
    setLoading(true);
    await apiClient()({ method: method, url: url, data: formData, config: config }).then(
      (response) => {
        setLoading(false);
        let msg = `Formulary ${response.status === 200 ? 'updated' : 'added'} successfully`;
        modalProp.content = [];
        modalProp.content.push(msg);
        modalProp.isError = false;
        setModalMsg(modalProp)
        setShowModal(true);
      }, (err) => {
        let errMsg = [];
        if (err.response.statusText === "Unauthorized") {
          errMsg.push("Unauthorized User");
        }
        else if (err?.response?.data?.errors) {
          let errors = err?.response?.data?.errors;
          errMsg = [];
          for (let x in errors) {
            errMsg.push(x + ' - ' + errors[x]);
          }
          modalProp.size = 'lg';
        }
        else {
          errMsg.push(err.response.data);
          modalProp.size = 'lg';
        }
        if (errMsg?.length > 0) {
          modalProp.content = errMsg;
          modalProp.isError = true;
          setModalMsg(modalProp)
          setShowModal(true);
        }
        setLoading(false);
      }
    );
  };

  const formularyValidation = (obj) => {
    let formError = {};
    formError["description"] = "";
    formError["defaultGenericFormularyStatus"] = "";
    formError["defaultBrandFormularyStatus"] = "";
    formError["defaultOTCFormularyStatus"] = "";
    formError["file"] = "";
    formError["isFormValid"] = true;
    let requiredFieldText = "This field is required";

    if (!obj.description) {
      formError.description = requiredFieldText;
      formError.isFormValid = false;
    }
    if (obj.description) {
      var isSpace = patten.test(obj.description[0])
      if (isSpace) {
        formError.description = "Description cannot begin with a space";
        formError.isFormValid = false;
      }
    }

    if (!obj.defaultGenericFormularyStatus) {
      formError.defaultGenericFormularyStatus = requiredFieldText;
      formError.isFormValid = false;
    }
    if (!obj.defaultBrandFormularyStatus) {
      formError.defaultBrandFormularyStatus = requiredFieldText;
      formError.isFormValid = false;
    }
    if (!obj.defaultOTCFormularyStatus) {
      formError.defaultOTCFormularyStatus = requiredFieldText;
      formError.isFormValid = false;
    }
    if (!obj.file && !id) {
      formError.file = requiredFieldText;
      formError.isFormValid = false;
    }
    return formError;
  };

  const handleClose = (redirectToHomePage) => {
    setShowModal(false);
    if (redirectToHomePage)
      navigate("/formularies");
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormulary({ ...formulary, [name]: checked });
    if (!checked) {
      formulary.file = '';
      document.getElementById('file').value = null;
    }
  };

  const validate = () => {
    if (prevStatus !== formulary.isActive) {
      let warningMsg = `You are about to ${formulary.isActive ? 'reactivate' : 'inactivate'} formulary. Are you sure you want to continue?`;
      setActiveInactiveWarningMsg(warningMsg);
      setShowWarningModal(true);
      return false;
    } else {
      formularyAdd();
    }
  }


  const handleYesClick = () => {
    setShowWarningModal(false);
    formularyAdd();
  }

  const handleNoClick = () => {
    setShowWarningModal(false);
  }

  const exportFormulary = async (e) => {
    e.preventDefault();
    const config = { headers: { "content-type": "text/csv;charset=UTF-8" } };
    await apiClient().get(`/api/formularies/${id}/${e.target.id}`, config).then(
      (response) => {
        JsonToCSVDownload(response.data, `formulary_${id}_version_${e.target.id}.csv`);
      }, (err) => {
        modalProp.content = [];
        modalProp.content.push(err?.response?.data);
        modalProp.isError = true;
        setModalMsg(modalProp)
        setShowModal(true);
      });
  }

  const validateCSV = () => {
    navigate("/formularies/upload-result", { state: { file: formulary.file, id: id } });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 py-3">
          <h1>In-House Formulary</h1>
          {loading ? <LoadSpinner /> : ''}
          <div className={loading ? 'd-none' : ''}>
            <div className="form-group row input-required">
              <label htmlFor="description" className="col-sm-3 col-form-label">
                Description:
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  data-testid="description"
                  maxLength="50"
                  minLength="1"
                  value={formulary.description}
                  onChange={handleInputChange}
                  onKeyUp={onKeyUp}
                />
                {formError.description && (
                  <span className="error" data-testid="descriptionErrorMsg">
                    {formError.description}
                  </span>
                )}
              </div>
            </div>
            <div className="form-group row input-required">
              <label
                htmlFor="defaultGenericFormularyStatus"
                className="col-sm-3 col-form-label"
              >
                Default Generic Form Status:
              </label>
              <div className="col-sm-4">
                <select
                  id="defaultGenericFormularyStatus"
                  name="defaultGenericFormularyStatus"
                  className="form-control"
                  data-testid="defaultGenericFormularyStatus"
                  value={formulary.defaultGenericFormularyStatus}
                  onChange={handleInputChange}
                >
                  <option key="gfEmpty" value="">
                    --Select--
                  </option>
                  {formularyStatus.map((data, index) => {
                    return (
                      <option key={data.key + `-gf`} value={data.key}>
                        {data?.value}
                      </option>
                    );
                  })}
                </select>
                {formError.defaultGenericFormularyStatus && (
                  <span
                    className="error"
                    data-testid="genericFormularyStatusErrorMsg"
                  >
                    {formError.defaultGenericFormularyStatus}
                  </span>
                )}
              </div>
            </div>
            <div className="form-group row input-required">
              <label
                htmlFor="defaultBrandFormularyStatus"
                className="col-sm-3 col-form-label"
              >
                Default Brand Form Status:
              </label>
              <div className="col-sm-4">
                <select
                  id="defaultBrandFormularyStatus"
                  name="defaultBrandFormularyStatus"
                  data-testid="defaultBrandFormularyStatus"
                  className="form-control"
                  value={formulary.defaultBrandFormularyStatus}
                  onChange={handleInputChange}
                >
                  <option key="bfEmpty" value="">
                    --Select--
                  </option>
                  {formularyStatus.map((data, index) => {
                    return (
                      <option key={data.key + `-bf`} value={data.key}>
                        {data?.value}
                      </option>
                    );
                  })}
                </select>
                {formError.defaultBrandFormularyStatus && (
                  <span className="error" data-testid="brandFormularyErrorMsg">
                    {formError.defaultBrandFormularyStatus}
                  </span>
                )}
              </div>
            </div>
            <div className="form-group row input-required">
              <label
                htmlFor="defaultOTCFormularyStatus"
                className="col-sm-3 col-form-label"
              >
                Default OTC Form Status:
              </label>
              <div className="col-sm-4">
                <select
                  id="defaultOTCFormularyStatus"
                  name="defaultOTCFormularyStatus"
                  data-testid="defaultOTCFormularyStatus"
                  className="form-control"
                  value={formulary.defaultOTCFormularyStatus}
                  onChange={handleInputChange}
                >
                  <option key="ofEmpty" value="">
                    --Select--
                  </option>
                  {formularyStatus.map((data, index) => {
                    return (
                      <option key={data.key + `-of`} value={data.key}>
                        {data?.value}
                      </option>
                    );
                  })}
                </select>
                {formError.defaultOTCFormularyStatus && (
                  <span className="error" data-testid="otcFormularyErrorMsg">
                    {formError.defaultOTCFormularyStatus}
                  </span>
                )}
              </div>
            </div>
            <div className={id ? 'form-group row' : 'form-group row input-required'}>
              <label htmlFor="file" className="col-sm-3 col-form-label">
                File Name:
              </label>
              <div className={(!formulary.isActive && id > 0) ? `col-sm-4  is-disabled` : 'col-sm-4'} data-testid="fileDiv">
                <div>
                  <input
                    type="file"
                    data-testid="file"
                    accept=".csv"
                    name="file"
                    id="file"
                    onChange={changeHandler}
                  />
                </div>
                {formError.file && (
                  <span className="error" data-testid="fileErrorMsg">
                    {formError.file}
                  </span>
                )}
              </div>
            </div>
            <div className="form-check">
              <div className="col-4 ml-n4 text-right">
                <input className="form-check-input"
                  id="isActive"
                  name="isActive"
                  type="checkbox"
                  data-testid="isActive"
                  onChange={handleCheckboxChange}
                  checked={formulary.isActive === true}
                />
                <label className="form-check-label" htmlFor="isActive">Active</label>
                {formulary.isActive}
              </div>
            </div>
            <div className="form-group row mt-2">
              <label htmlFor="file" className="col-sm-3 col-form-label"></label>
              <div className={formulary.file ? 'col-4' : 'col-4 is-disabled'}>
                <button
                  type="button"
                  data-testid="validateBtn"
                  className="btn btn-primary"
                  onClick={validateCSV}>
                  Validate
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-7">
                <div className="p-2 text-right">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    data-testid="backBtn"
                    onClick={() => navigate("/formularies")}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    data-testid="saveBtn"
                    className="btn btn-primary"
                    onClick={validate}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            {id && (
              <div className="row">
                <div className={formulary?.versions?.length > 5 ? 'innerscroll formulary-versions' : ''}>
                  <div className="p-2">
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Version</th>
                            <th>Date/Time</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formulary.versions?.length === 0 ? (
                            <tr>
                              <td className="no-data" colSpan="3">
                                <aside>No Data Found</aside>
                              </td>
                            </tr>
                          ) : (
                            <>
                              {formulary.versions?.map((val) => {
                                return (
                                  <tr key={`version-key-${val.id}`}>
                                    <td>{val.version}</td>
                                    <td> <Moment format="MM/DD/YYYY h:mm:ss a">
                                      {val.createdOn}
                                    </Moment></td>
                                    <td><img alt={val.version} src="../../images/IconDownloadFlatFile.gif" id={val.version} onClick={exportFormulary}></img></td>
                                  </tr>
                                );
                              })}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <SimpleModal showModal={showModal} modalProp={modalMsg} handleClose={handleClose}></SimpleModal>
          <YesNoModal showModal={showWarningModal} content={activeInactiveWarningMsg} handleYesClick={handleYesClick} handleNoClick={handleNoClick}></YesNoModal>
        </div>
      </div>
    </div>
  );
}

export default FormularyAdd;
