import React from "react";

// props: `small` or `small={true}`

const LoadSpinner = (props) => (
  <div data-testid="spinner">
    <div className="sr-only">Loading</div>
    <div className={`spinner ${props.small ? "spinner-sm" : ""}`}>
      <div className="lspinner"></div>
    </div>
  </div>
);

export default LoadSpinner;
