import React, { useRef, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import IdleTimer from 'react-idle-timer';
import Modal from 'react-bootstrap/Modal';

export const IdleTimeOutModal = () => {
	const { oktaAuth } = useOktaAuth();
  
  const idleTimeout = 600; // in seconds, session timeout
  const warningTimeout = 60; // in seconds, warning countdown on modal
  
	const idleTimerRef = useRef(null);
  const sessionCountdownRef = useRef(null);
  const modalCountdownRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [modalCountdown, setModalCountdown] = useState(warningTimeout);
	
  const handleOnIdle = () => {
    setShowModal(true);
    sessionCountdownRef.current = setTimeout(function(){handleLogout()},warningTimeout*1000);
  }
  
  const handleCountdown = () => {
    setModalCountdown(warningTimeout);
    modalCountdownRef.current = setInterval(() => {
      setModalCountdown((modalCountdown) => modalCountdown -1)
    }, 1000);
    return () => clearInterval(modalCountdownRef.current)
  }
  
  const handleClose = () => {
    oktaAuth.tokenManager.renew('accessToken')
    .then(function (newToken) {
      localStorage.setItem('accessToken', newToken.accessToken);
      clearInterval(modalCountdownRef.current);
      clearTimeout(sessionCountdownRef.current);
      setShowModal(false);
      setModalCountdown(warningTimeout);
    });

  }
  
  const handleLogout = async () => {
    clearInterval(modalCountdownRef.current);
    clearTimeout(sessionCountdownRef.current);
    localStorage.clear();
    oktaAuth.tokenManager.clear();
    await oktaAuth.signOut();
  }

    return (
			<IdleTimer ref={idleTimerRef} timeout={1000 * (idleTimeout-warningTimeout) * 1} onIdle={handleOnIdle} crossTab={{emitOnAllTabs:true}} debounce={500}>
        <Modal show={showModal} onShow={handleCountdown} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header>
            <Modal.Title>Session Timeout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
							You will be logged out in: <strong>{modalCountdown} {modalCountdown!==1?'seconds':'second'}</strong>
						</Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger" onClick={handleLogout}>
                  Log Off
              </button>
              <button className="btn btn-primary" onClick={handleClose}>
                  Stay Logged In
              </button>
            </Modal.Footer>
        </Modal>
			</IdleTimer>
    )
}