import React, { useEffect } from 'react';


import Footer from '../Components/Shared/Common-UI/Footer';
import Header from '../Components/Shared/Common-UI/Header';
import { IdleTimeOutModal } from './IdleTimeoutModal';


function View(props) {
  const Container = props.name;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${props.title} | MatrixCare Formulary Service`;
    //loads Neo stuff when done rendering view
    document.dispatchEvent(new Event('neoInitEvent'));
    document.querySelector('body').classList.contains('mxcLoginBody') && document.querySelector('body').classList.remove('mxcLoginBody');
  }, [props.title])



  return (
      <>
      <Header />
      <Container title={props.title} />
      <Footer />
      <IdleTimeOutModal />
      </>
  )

}

export default View;