import React  from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";


// Okta
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuthConfig,oktaSignInConfig } from './config';

import Login from './Login';
import ErrorNotFound from './Components/Views/ErrorNotFound';

// views
import Home from './Components/Views/Home';
import View from './Components/View';
import Formulary from './Components/Views/Formulary';
import FormularyAdd from './Components/Views/FormularyAdd';
import ProtectedRoute from './ProtectedRoute';
import FormularyUploadResult from './Components/Views/FormularyUploadResult';

const oktaAuth = new OktaAuth(oktaAuthConfig);


const AppWithRouterAccess = () => {

  const history = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  const customAuthHandler = () => {
    history.push('/login');
  };

 
  return (
    <Security
    oktaAuth={oktaAuth}
    onAuthRequired={customAuthHandler}
    restoreOriginalUri={restoreOriginalUri}
    >
      
      <Routes>     
        <Route  path='/login' element={<Login config={oktaSignInConfig} />} />
        <Route path='/login/callback' element={LoginCallback} />      
        {/* Protected Routes */}
        <Route path="/" element={
            <ProtectedRoute> <View name={Home} title="Home" />   </ProtectedRoute>
          } />
           <Route path="/formularies" element={
            <ProtectedRoute> <View name={Formulary} title="Formulary" />   </ProtectedRoute>
          } />
          <Route path="/formularies/add" element={
            <ProtectedRoute> <View name={FormularyAdd} title="Formulary Add" />   </ProtectedRoute>
          } />
          <Route path="/formularies/edit/:id" element={
            <ProtectedRoute> <View name={FormularyAdd} title="Formulary Edit" />   </ProtectedRoute>
          } />
           <Route path="/formularies/upload-result" element={
            <ProtectedRoute> <View name={FormularyUploadResult} title="Formulary Upload Result" />   </ProtectedRoute>
          } />
        <Route path="*"  element={
            <ProtectedRoute> <View name={ErrorNotFound} title="Error: Page Not Found!" status={404} />  </ProtectedRoute>
          } />     
    </Routes>   
      </Security>
  );
};


export default AppWithRouterAccess;


