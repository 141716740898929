import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';



function Header() {

	const [hide] = useState(false);

	const { oktaAuth } = useOktaAuth();

	const logout = async () => {
		localStorage.clear();
		oktaAuth.tokenManager.clear();
		await oktaAuth.signOut();
	}

	return (
		<header>
			<div className="facilityLocation d-none d-md-block">
				<span>Formulary Service</span>
			</div>

			<>
				<nav className="navbar navbar-expand-md menu-header navbar-default navbar-light">
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#appMenu" aria-controls="appMenu" aria-expanded="false" aria-label="Toggle Navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<Link to="/" className="navbar-brand"><img width='280' height='40' alt="MxC logo" src="../../images/MatrixCare_logo.svg" /></Link>
					<button className="navbar-toggler avatar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle Navigation">
						<img alt="Neutral" className="rounded-circle user-avatar ll" width="40" height="40" src="../../images/neutral.jpg" title="Neutral" />		</button>
					<div className="collapse navbar-collapse" id="navbarContent">
						<ul className="navbar-nav ml-auto">
							{hide && (<span><li className="nav-item">
								<form action="boilerplate-one.html" method="GET" className="globalSearchForm">
									<div className="input-group input-group-sm globalSearchGroup">
										<label id="globalSearchLabel" htmlFor="globalSearchField" className="sr-only">Search:</label>
										<input type="text" id="globalSearchField" name="searchTerm" placeholder="Search" aria-label="Search" aria-describedby="globalSearchLabel" />
										<input type="hidden" name="searchresults" value="display" />
									</div>
								</form>
								<a href="#searchTrigger" id="navbarsearchtrigger" className="nav-link nav-icon navSearchTrigger">
									<i className="fa fa-search"></i>
								</a>
							</li>
								<li className="nav-item">
									<a href="#nullURL" className="nav-link nav-icon nav-notification"><i className="fa fa-bell-o"></i><span className="badge badge-pill badge-primary">3</span></a>
								</li>
								<li className="nav-item">
									<a href="#nullURL" className="nav-link nav-icon"><i className="fa fa-question-circle-o"></i></a>
								</li></span>)}
							<li className="nav-item">
								{/* {loginLink} */}
							</li>
							<li className="nav-item">
								<a id="navbarLogOffCommand" className="nav-link" onClick={logout} href="#nullURL">Log Off</a>
							</li>
							<li className="nav-item">																																																					
								<a className="nav-link" href="#nullURL" id="navbarUsernameMenu" data-toggle="dropdown" data-boundary="window" data-offset="25px" aria-expanded="false">
									<img src="../../images/neutral.jpg" className="rounded-circle" width="40" height="40" alt="Neutral" />
									{localStorage?.getItem("nameOfUser")}
								</a>								
							</li>
							<li className="nav-item dropdown d-none">
								<a className="nav-link dropdown-toggle dropdown-usermenu" href="#nullURL" id="navbarUsernameMenu" data-toggle="dropdown" data-boundary="window" data-offset="25px" aria-expanded="false">
									<img src="../../images/neutral.jpg" className="rounded-circle" width="40" height="40" alt="Neutral" />
									{localStorage?.getItem("nameOfUser")}
								</a>
								<div className="dropdown-menu dropdown-menu-positioned" aria-labelledby="navbarUsernameMenu">
									<a className="dropdown-item" href="#nullURL" onClick={() => window.open(process.env.REACT_APP_OKTA_BASE_URL + '/enduser/settings', "_blank", "toolbar=no,scrollbars=yes,resizable=no,top=500,left=300,width=800,height=800")}>Profile</a>

									{hide && (<span><a className="dropdown-item" href="#nullURL">Security</a>
										<a className="dropdown-item" href="#nullURL">Settings</a>
										<a className="dropdown-item" href="#nullURL">Help</a></span>)}

								</div>
							</li>
						</ul>
					</div>
				</nav>
				<nav className="navbar navbar-expand-md menu-bar menu-shadow menu-bar-light">
					<div className="collapse navbar-collapse" id="appMenu">
						<ul className="nav justify-content-center">
							<li className="nav-item">
								<NavLink id="navbarHomeCommand" exact="true" className="nav-link" to="/" >Home</NavLink>
							</li>
							<li className="nav-item">
								<NavLink id="navbarFormularyCommand" exact="true" className="nav-link" to="/formularies" >Formulary</NavLink>
							</li>
						</ul>
					</div>
				</nav>
			</>

		</header>
	);

}

export default Header;