


import axios from 'axios';

let instance;

const apiClient = () => {
  // If the instance has already been instantiated then return it. Otherwise create a new instance.
  if (instance !== undefined)
    return instance;


    instance = axios.create({
        baseURL: process.env.REACT_APP_API_HOST
  });


  return instance;
}

 export default apiClient;



// import axios from 'axios';

// const instance = axios.create({
//     baseURL: process.env.REACT_APP_API_HOST
// });

// let apiClients;

// const apiClient = () => {
//     if (apiClients === undefined) {
//         apiClients = instance;
//     }
//     return apiClients;
// };

// export default apiClient;
