import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// SASS
import './Styles/App.css';


// Router
import AppWithRouterAccess from './AppWithRouterAccess';

function App() {
  return (
    
    <Router>   
        <AppWithRouterAccess />
    </Router>
  )
}

export default App
