import React from "react";

function ErrorNotFound(props){
	
	return(
		
    <main className="col-12 py-3">
      <h1>Page Not Found </h1>
			<p>These aren't the droids you're looking for.</p>
    </main>
		
	)
	
}

export default ErrorNotFound 