import { Navigate } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';


function ProtectedRoute({ children }) {
  const { authState } = useOktaAuth();

  if (!authState) {
    return null
  }

  return authState?.isAuthenticated ? children : <Navigate to="/login" />;
};


export default ProtectedRoute;