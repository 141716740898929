import React from "react";
import { useOktaAuth } from '@okta/okta-react';
import Logout from "../../Logout";


function Home(props){
  const { authState } = useOktaAuth();   
  if (authState.isPending) return null;  
	return(
		<div className="container">
			<div className="row">
        <main className="col-12 py-3">
        <h1>{props.title}</h1>
        <Logout isButton={true} />    
        </main>
      </div>
    </div>
	)
	
}

export default Home 