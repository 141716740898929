import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

export default function Logout(props) {
  
  const { oktaAuth } = useOktaAuth();
  
  const logout = async () => {
    localStorage.clear();  //Signs user out of App
    oktaAuth.tokenManager.clear(); //Signs user out of App
    await oktaAuth.signOut(); //Signs user out of Okta
  }
  
  return (
    <a href="/" onClick={logout} className={props.isButton?'btn btn-primary':'nav-link'}>Log Off</a>
  )
}
