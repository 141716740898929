import React from "react";


function Footer() {
    return (
            <footer className="footer">
                <div className="container">
                    <div className="text-muted">
                        <i className="fa fa-copyright"></i> 2022 MatrixCare is a registered trademark of MatrixCare. All rights reserved.
                </div>
                </div>
            </footer>
    );

}
export default Footer;