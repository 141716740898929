import apiClient from "../../client/src/api";


apiClient().interceptors.request.use(
  (config) => {
    let accessToken = localStorage.getItem('accessToken');
    const defaultOptions = {
      Authorization: accessToken ? `Bearer ${accessToken}` : ''
    };

    config.headers = defaultOptions;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

apiClient().interceptors.response.use((response) => {
  return response;
}, (err) => {
  return Promise.reject(err);
});