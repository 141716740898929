import React,{ useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';

function SimpleModal(props) {
  const [showModal, setShowModal] = useState(false);

  
  useEffect(() => {
    if (props.showModal) {
      setShowModal(props.showModal);
    }
  }, [props.showModal]);

  const handleClose = () => {
    setShowModal(false);
    props.handleClose(props.modalProp.isError ? false:true);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size={props?.modalProp?.size}
      
    >    
        <Modal.Header>
          <Modal.Title>{props?.modalProp?.isError ?          
          <h4 class="modal-title"><i class="fas fa-ban neo-danger"></i>&nbsp; Error</h4>
          :
          <h4 class="modal-title"><i class="fa fa-check neo-success"></i>&nbsp; Success</h4>
          }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props?.modalProp?.content?.length > 0 &&
          props?.modalProp?.content?.map((item) => {
            return (
              <>
                <span data-testid="successMessage" className={props.modalProp.isError ? 'error':''}>{item}</span>
                <br />
              </>
            );
          })}
      </Modal.Body>
      <Modal.Footer>
          <button className="btn btn-cancel" data-testid="closeBtn" onClick={handleClose}>
            Close
          </button>       
      </Modal.Footer>
    </Modal>
  );
}


export default SimpleModal;
