import React from "react";
import { Navigate } from "react-router-dom";
import OktaSignInWidget from "./OktaSignInWidget";
import { useOktaAuth } from "@okta/okta-react";
import "./Styles/Components/loginForm.scss";

const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();
  !document.querySelector("body").classList.contains("mxcLoginBody") &&
    document.querySelector("body").classList.add("mxcLoginBody");

  const onSuccess = (tokens) => {
    localStorage.setItem("accessToken", tokens.accessToken.accessToken);
    localStorage.setItem("nameOfUser", tokens.idToken.claims.name);
    let userGroups = tokens.accessToken?.claims?.at_groups;
    let authorizedGroups;
    if (process.env.REACT_APP_OKTA_CLAIMS) {
      authorizedGroups = process.env.REACT_APP_OKTA_CLAIMS.split(",");
    }
    let validUser = false;
    if (userGroups && userGroups.length > 0 && authorizedGroups) {
      for (var item in userGroups) {
        for (var index in authorizedGroups) {
          if (authorizedGroups[index] === userGroups[item]) {
            validUser = true;
            break;
          }
        }
      }
    }
    if (validUser){
      localStorage.setItem('accessToken', tokens.accessToken.accessToken);
      localStorage.setItem('nameOfUser', tokens.idToken.claims.name);
      oktaAuth.handleLoginRedirect(tokens);
    }
    else document.getElementById("showPopupBtn").click();
  };

  const onError = (err) => {
    console.log("error logging in", err);
  };

  const logOut = () => {
    oktaAuth.tokenManager.clear(); //Signs user out of App
    oktaAuth.signOut(); //Signs user out of Okta
  };

  if (authState?.isPending) return null;

  return authState?.isAuthenticated ? (
    <Navigate to={{ pathname: "/" }} />
  ) : (
    <div className="mxc-login">
      <div className="bannerImg"></div>
      <OktaSignInWidget
        config={config}
        onSuccess={onSuccess}
        onError={onError}
      />

      <button
        type="button"
        id="showPopupBtn"
        className="d-none"
        data-backdrop="static"
        data-keyboard="true"
        data-toggle="modal"
        data-target="#unAuthorizedModal"
      ></button>

      <div
        className="modal fade"
        tabIndex="-1"
        id="unAuthorizedModal"
        role="dialog"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                <i className="fas fa-ban neo-danger"></i>&nbsp; Error
              </h4>
              <button type="button" className="close" data-dismiss="modal"  onClick={logOut}>
                Close Modal
              </button>
            </div>
            <div className="modal-body">
              <p>
                <b>You are not authorized to access this page</b>
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-cancel"
                data-dismiss="modal"
                onClick={logOut}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
