import React,{ useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';

function YesNoModal(props) {
  const [showModal, setShowModal] = useState(false);

  
  useEffect(() => {
    if (props.showModal) {
      setShowModal(props.showModal);
    }
  }, [props.showModal]);

  const handleNoClick = () => {
    setShowModal(false);
    props.handleNoClick();
  };

  const handleYesClick = () => {
    setShowModal(false);
    props.handleYesClick();
  };

  return (
    <Modal
      show={showModal}
      onHide={handleNoClick}
      backdrop="static"
      keyboard={false}>    
        <Modal.Header>
          <Modal.Title><h4 className="modal-title"><i className="fa fa-warning neo-warning"></i>&nbsp; Warning</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>  
            <span data-testid="warningMessage"><b>{props.content}</b></span>               
      </Modal.Body>
      <Modal.Footer>
            <button type="button" className="btn btn-primary" data-testid="yesBtn" data-dismiss="modal" onClick={handleYesClick}>Yes</button>
			<button type="button" className="btn btn-cancel" data-testid="noBtn" data-dismiss="modal" onClick={handleNoClick}>No</button>
      </Modal.Footer>
    </Modal>
  );
}


export default YesNoModal;
